var pageglobals = new PageGlobals();

var validator = new Validator();
validator.addItem(newValidateItem('USERNAMEEF', 'Email'));
validator.addItem(newValidateItem('PASSWORDEF', 'Password'));

var controller = {
    initialize: function () {
        controller.initCheckbox();
        getForm().onsubmit = controller.login;

        // stop looping after 5 minutes sitting on this page to keep the bandwidth usage down
        setTimeout(controller.pauseBgVideo, 300000);
        focusOn('USERNAMEEF');
    },
    login: function () {
        // var video = $('#loginBgVid').hide()[0];
        // video.pause();
        // video.src = null;
        // if (video.mozSrcObject) {
        // video.mozSrcObject = null;
        // }
        if (!passwordPanel.isOpen() && validator.isValid()) {
            submitForm('LOGINACTION');
        }
    },
    pauseBgVideo: function () {
        // stop looping at the end
        $('#loginBgVid').removeAttr('loop');

        // // pause and seek to the gears shot
        // var vid = $('#loginBgVid')[0];
        // vid.pause();
        // vid.currentTime = 32;
    },
    initCheckbox: function () {
        var $checkbox = $('.js-rememberMe');
        var storageKey = 'collaterate:rememberMeLogin';

        if (!$checkbox.length) {
            return;
        }

        setupCheckboxEvents();
        setInitialCheckboxState();

        return;

        function setInitialCheckboxState () {
            $checkbox[0].checked = getRememberMeValue();
        }

        function setupCheckboxEvents () {
            $checkbox.on('change', handleCheckboxChange);
        }

        function handleCheckboxChange (e) {
            setRememberMeValue($checkbox[0].checked);
        }

        function getRememberMeValue () {
            var storageObject = window.localStorage.getItem(storageKey);

            if (storageObject) {
                return JSON.parse(storageObject).rememberMe;
            }

            return false;
        }

        function setRememberMeValue (value) {
            var storageObject = {
                rememberMe: value
            };

            window.localStorage.setItem(storageKey, JSON.stringify(storageObject));
        }
    }
};

$(controller.initialize);
