(function (window) {
    'use strict';

    window.tbg = window.tbg || {};
    window.tbg.getTimelyGreeting = getTimelyGreeting;

    function getTimelyGreeting () {
        var greeting = 'Hello.';
        var localHour = new Date().getHours();

        if (localHour < 12) {
            /* hour is before noon */
            greeting = 'Good Morning.';
        } else if (localHour >= 12 && localHour <= 17) {
            /* hour is from noon to 5:59 pm */
            greeting = 'Good Afternoon.';
        } else if (localHour > 17 && localHour <= 24) {
            /* hour is after 5pm, so it is between 6pm and midnight */
            greeting = 'Good Evening.';
        }

        return greeting;
    }
}(window));
