var passwordPanel = {
    open: function () {
        var content = '';
        content += '<div class="passwordResetPanel">';

        content += '<div>';
        content += tags.h3('Reset Your Password');
        content += '</div>';

        content += tags.p('Please enter your email and click continue. An email will be sent with instructions for updating your password.');

        content += forms.ef(PASS_USER_NAME_EF, '', {
            id: PASS_USER_NAME_EF,
            onkeypress: 'passwordPanel.keyUp(event)',
            placeholder: 'Email'
        });

        content += forms.button('Continue', {
            onclick: 'passwordPanel.continueClicked()'
        });

        content += '</div>'; // .passwordResetPanel

        savePanel.open(content, {
            showSave: false,
            showCancel: false,
            showClose: true,
            afterShow: function () {
                $('#' + PASS_USER_NAME_EF).focus();
            }
        });
    },
    isOpen: function () {
        return savePanel.isOpen;
    },
    keyUp: function (event) {
        if (isEnterEvent(event)) {
            this.continueClicked();
        }
    },
    continueClicked: function () {
        var passwordResetValidator = new Validator();
        passwordResetValidator.addItem(newValidateItem(PASS_USER_NAME_EF, 'Email'));

        if (passwordResetValidator.isValid()) {
            var self = this;

            var cometRequest = comet.newRequest(chandler, REQUEST_PASSWORD_RESET_ACTION, function (cometRequest) {
                growlMessages.showAll(cometRequest);

                if (cometRequest.hasErrorMessages()) {
                    $('#' + PASS_USER_NAME_EF).focus();
                } else {
                    savePanel.close();
                }
            });

            cometRequest.addFormField(PASS_USER_NAME_EF, false, document);
            cometRequest.send();
        }
    }
};
